import { APPOINTMENT_STATUSES } from '../../../../constants/constants';

const fileTypes = {
  'image/jpeg': 'JPEG',
  'image/png': 'PNG',
  'application/pdf': 'PDF',
  'text/plain': 'TXT',
  'application/msword': 'DOC',
  'application/vnd.ms-excel': 'XLS',
  'application/vnd.ms-powerpoint': 'PPT',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX'
};

const allStatus = [
  { name: 'Requested', color: 'orange', value: APPOINTMENT_STATUSES.REQUESTED },
  { name: 'Payment Requested', color: 'orange', value: APPOINTMENT_STATUSES.PAYMENT_REQUESTED },
  { name: 'Confirmed', color: 'blue', value: APPOINTMENT_STATUSES.CONFIRMED },
  { name: 'Done', color: 'green', value: APPOINTMENT_STATUSES.COMPLETED },
  { name: 'Canceled', color: 'red', value: APPOINTMENT_STATUSES.CANCELED },
  { name: 'Protocol', color: 'orange', value: APPOINTMENT_STATUSES.PROTOCOL },
];

export { allStatus };
export default fileTypes;
