import './i18n';

import React from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import GlobalStyles from './assets/styles/globalStyle';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import { useStore } from './store/store';
import 'antd/dist/antd.css';

function App() {
  const newStore = useStore({});
  const persistor = persistStore(newStore);

  return (
    <Provider store={newStore}>
      <PersistGate loading={(<div />)} persistor={persistor}>
        <AppProvider>
          <>
            <GlobalStyles />
            <Routes />
          </>
        </AppProvider>
      </PersistGate>
    </Provider>
  );
}
Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
