import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';

import App from '../redux/app/reducer';
import Auth from '../redux/auth/reducer';
import Calendar from '../redux/calendar/reducer';
import Notes from '../redux/notes/reducer';
import Todos from '../redux/todos/reducer';
import Contacts from '../redux/contacts/reducer';
import Cards from '../redux/card/reducer';
import Chat from '../redux/chat/reducers';
import DynamicChartComponent from '../redux/dynamicEchart/reducer';
import ThemeSwitcher from '../redux/themeSwitcher/reducer';
import Invoices from '../redux/invoice/reducer';
import LanguageSwitcher from '../redux/languageSwitcher/reducer';
import drawer from '../redux/drawer/reducer';
import modal from '../redux/modal/reducer';
import rootSaga from '../redux/root-saga';

let store;

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [],
};

const reducers = combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Calendar,
  Notes,
  Todos,
  Contacts,
  Cards,
  Chat,
  DynamicChartComponent,
  Invoices,
  modal,
  drawer,
});

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, reducers);

export const setupStore = (preloadedState) => {
  const store = configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: [...getDefaultMiddleware(), sagaMiddleware],
  });
  sagaMiddleware.run(rootSaga);
  return store;
};

export const initializeStore = (preloadedState) => {
  let initialStore = store || setupStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    initialStore = setupStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return initialStore;
  // Create the store once in the client
  if (!store) store = initialStore;

  return initialStore;
};

export function useStore(initialState) {
  return useMemo(() => initializeStore(initialState), [initialState]);
}
