import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en';
import de from './de';

const resources = {
  en,
  de
};

export const appLocales = Object.keys(resources);
const translationsUrl = process.env.REACT_APP_TRANSLATION_URL;

const customDetector = {
  name: 'languageDetector',
  lookup() {
    let appLanguage = appLocales[0];
    const locale = window.navigator.language;
    const localeShort = locale.split(/[_-]/)[0];
    const isPresent = appLocales.find((item) => item === localeShort);
    const storeLang = localStorage.getItem('language');
    if (storeLang) {
      appLanguage = storeLang;
    } else if (isPresent) {
      appLanguage = localeShort;
    }
    return appLanguage;
  },
};

const detectorOptions = {
  order: ['languageDetector', 'localStorage'],
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(customDetector);

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: 'de',
    react: {
      useSuspense: true,
    },
    detection: detectorOptions,
    defaultLocale: 'de',
    referenceLng: 'de',
    locales: ['en', 'de'],
    lng: ['en', 'de'],
    ns: ['general'],
    defaultNS: 'general',
    backend: {
      loadPath: `${translationsUrl}{{lng}}/{{ns}}.json`,
    },
  });

export default i18n;
