import { Map } from 'immutable';
import moment from 'moment';
import {
  isEmpty, isString, isObject, isArray, findIndex
} from 'lodash';
import fileTypes from './utilityData';

export function clearToken() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
}

export function getToken() {
  try {
    const idToken = localStorage.getItem('id_token');
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function getLanguage() {
  try {
    const language = localStorage.getItem('language');
    return new Map({ language });
  } catch (err) {
    return new Map();
  }
}

export function getUserData() {
  try {
    const userData = localStorage.getItem('user_data');
    return new Map({ userData });
  } catch (err) {
    clearToken();
    return {};
  }
}

export function arrayEqual(array1, array2) {
  return array1.sort().toString() === array2.sort().toString();
}

export function timeDifference(date, t) {
  const now = new Date();
  const timeDiff = now.getTime() - new Date(date).getTime();

  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return t('daysAgo', { days });
  }
  if (hours > 0) {
    return t('hoursAgo', { hours });
  }
  if (minutes > 0) {
    return t('minutesAgo', { minutes });
  }
  return t('fewSecondsAgo');
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  } if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}
export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function getParsedJson(json) {
  if (isString(json) && !isEmpty(json)) return JSON.parse(json);
  return json;
}

export function getHours() {
  const timesArr = [];
  for (let i = 0; i <= 24; i++) {
    if (i < 10) {
      timesArr.push({
        value: `0${i}:00`,
        label: `0${i}:00`,
      });
    } else if (i === 24) {
      timesArr.push({
        value: '23:59',
        label: '23:59',
      });
    } else {
      timesArr.push({
        value: `${i}:00`,
        label: `${i}:00`,
      });
    }
  }
  return timesArr;
}

export function disabledDate(current, type, data) {
  let index = -1;
  if (isArray(data) && data.length > 0) {
    index = findIndex(data, (item) => {
      return (
        current.isSameOrBefore(moment(item.end_date, 'YYYY-MM-DD'))
        && current.isSameOrAfter(moment(item.start_date, 'YYYY-MM-DD'))
      );
    });
  }

  // Can not select days before today and today
  if (type === 'today') {
    return current < moment().startOf('day');
  }
  return (current && current < moment().endOf('day')) || index > -1;
}

export function getFullName(fname, lname) {
  if (fname && lname) return `${fname} ${lname}`;
  if (fname) return fname;
  return '';
}

export function dropdownFilter(input, option) {
  if (option.type.isSelectOptGroup) {
    option.props.children.map((item) => {
      return item?.props?.children
        ? item?.props?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        : false;
    });
  } else {
    return option.props.children
      ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      : false;
  }
}
export function countryFilter(input, option) {
  return (
    option.props.countryname.toLowerCase().indexOf(input.toLowerCase()) >= 0
  );
}

export function chatFilesVal(type) {
  const fTypes = isObject(fileTypes) ? fileTypes : {};
  if (fTypes[type]) {
    return true;
  }
  return false;
}

export function formatFileSize(bytes, decimalPoint = 2) {
  if (bytes === 0) return '0 Bytes';
  const k = 1000;
  const dm = decimalPoint || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function bookingDateFormat(nxtBooking = {}) {
  let dateTime = '-';
  const nextAppointment = isObject(nxtBooking) ? nxtBooking : {};
  const start_time = nextAppointment.start_time
    ? ` ${nextAppointment.start_time}`
    : '';
  if (!isEmpty(nextAppointment) && start_time) {
    const fmDate = `${nextAppointment.date}${start_time}`;
    dateTime = moment(fmDate).format('DD-MM-YYYY [um] hh:mm [Uhr]');
  }
  return dateTime;
}

export const prepareCategories = (arr, langCode) => {
  return arr.map(
    (category) => ({
      ...category,
      localization: (category.localization.filter((item) => item.languageCode === langCode))
    })
  );
};
