import {
  all, takeEvery, put, fork
} from 'redux-saga/effects';
import { isObject, isString } from 'lodash';

import { clearToken, getUserData } from '../../shared/common/library/helpers/utility';
import actions from './actions';
import { languageHelpers } from '../../utils/languageHelpers';

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    const {
      accessToken, refreshToken, user
    } = payload;
    if (accessToken) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        accessToken,
        refreshToken,
        user,
        profile: 'Profile',
      });
    } else {
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    const user = isObject(payload.user) ? JSON.stringify(payload.user) : payload.user;
    const userLocal = user || '';
    const accessToken = isString(payload.accessToken) ? payload.accessToken : '';
    const refreshToken = isString(payload.refreshToken) ? payload.refreshToken : '';

    yield localStorage.setItem('accessToken', accessToken);
    yield localStorage.setItem('refreshToken', refreshToken);
    yield localStorage.setItem('userData', userLocal);
    yield localStorage.setItem('language', languageHelpers());
  });
}

export function* setUser() {
  yield takeEvery('SET_USER', function* (payload) {
    const user = isObject(payload.user) ? JSON.stringify(payload.user) : payload.user;
    const userLocal = user || '';
    const accessToken = payload.accessToken || '';
    const refreshToken = payload.refreshToken || '';
    localStorage.setItem('userData', userLocal);
    yield put({
      type: actions.LOGIN_SUCCESS,
      user: payload.user,
      accessToken,
      refreshToken
    });
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield clearToken();
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const accessToken = localStorage.getItem('accessToken') || '';
    const refreshToken = localStorage.getItem('refreshToken') || '';
    const user = getUserData().get('userData');
    if (accessToken && refreshToken) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        accessToken,
        refreshToken,
        user,
        profile: 'Profile',
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(setUser),
  ]);
}
