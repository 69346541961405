import { createGlobalStyle } from 'styled-components';
import { palette, font } from 'styled-theme';
// import 'antd/dist/antd.css';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Netto';
    src: url('/fonts/netto.ttf');
    font-style: normal;
    font-display: 'swap';
    font-weight: 400;
    src: url('/fonts/netto.ttf') format('ttf'), url('/fonts/netto.woff') format('woff'), url('/fonts/netto.ttf') format('opentype'), url('/fonts/netto.eot') format('eot');
  }

  .viewChatLink {
    fontSize: 14px;
    color: ${palette('primary', 0)} !important;
  }

  .smallIconImg { 
    width: 18px;
    height: 18px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'Netto';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;
      
      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }


/*-----------------------------------------------*/ 
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
}

font-family: ${font('primary', 0)};

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
strong,
footer,
svg {
  &::selection {
    background: ${palette('primary', 0)};
    color: #fff;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &.remForgotPssd {
    margin-top: 20px !important;
    margin-bottom: 30px !important;
  }
}

.isoRightComponent {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/********** Add Your Global CSS Here **********/

body {
  -webkit-overflow-scrolling: touch;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: 'Netto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/* Instagram Modal */

.ant-modal-wrap.instagram-modal .ant-modal {
  max-width: 935px;
  width: 100% !important;
}

@media only screen and (max-width: 991px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    padding: 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    max-width: 580px;
  }
}

.ant-modal-wrap.instagram-modal .ant-modal-content {
  border-radius: 0;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close {
  position: fixed;
  color: #fff;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close i {
  font-size: 24px;
}

.ant-modal-wrap.instagram-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}

/********** Add Your Global RTL CSS Here **********/

/* Popover */

html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */

html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */

html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */

html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */

html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */

html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */

html[dir='rtl'] .dz-hidden-input {
  display: none;
}

/* Custom global styles */

/* GENERAL */
.mt15 {
  margin-top: 15px;
}
.mt25 {
  margin-top: 25px;
}


/* INPUT */

.ant-input:focus, .ant-input:hover, .ant-input-number:hover , .ant-input-number:focus, .ant-input-number-focused {
  border-color: ${palette('primary', 0)} !important;
  box-shadow: 0 0 0 2px ${palette('primary', 14)} !important;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color:  ${palette('primary', 0)} !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}


/* TABLE */

.ant-table-row-expand-icon:focus, .ant-table-row-expand-icon:hover, .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on, .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on,
.ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter
{
  color: ${palette('primary', 0)} !important;
}

/* PAGINATION */
.ant-pagination .ant-pagination-prev:focus:not(.ant-pagination-disabled) .ant-pagination-item-link, .ant-pagination .ant-pagination-next:focus:not(.ant-pagination-disabled) .ant-pagination-item-link,.ant-pagination .ant-pagination-prev:hover:not(.ant-pagination-disabled) .ant-pagination-item-link,.ant-pagination .ant-pagination-next:hover:not(.ant-pagination-disabled) .ant-pagination-item-link {
  color: ${palette('primary', 0)};
  border-color: ${palette('primary', 0)};
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon  {
  color: ${palette('primary', 0)} !important;
}


/* CHECKBOX */

.ant-checkbox-checked .ant-checkbox-inner, .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: ${palette('primary', 0)} !important;
  border-color: ${palette('primary', 0)} !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner, .ant-checkbox-checked::after {
  border-color: ${palette('primary', 0)} !important;
}


/* DROPDOWN */

.ant-dropdown {
  .ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected, .ant-dropdown-menu-item-selected > a, .ant-dropdown-menu-submenu-title-selected > a {
    color: ${palette('primary', 0)} !important;
    background-color: ${palette('primary', 14)} !important;
  }
  .ant-table-filter-dropdown-link {
    color: ${palette('primary', 0)} !important;
  }
  .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
    background-color: ${palette('primary', 14)} !important;
  }
}

/* SPINNER */

.ant-spin {
  color: ${palette('primary', 0)} !important;
  & .ant-spin-dot-item {
    background-color: ${palette('primary', 0)};
  }
}



/* BUTTON */

.ant-btn:hover, .ant-btn:focus, .ant-btn:active, .ant-btn.active {
  color: ${palette('primary', 0)} !important;
  border-color: ${palette('primary', 0)} !important;
}
.ant-btn.ant-btn-primary {
  color: #ffffff !important;
  background-color: ${palette('primary', 0)} !important;
  border-color: ${palette('primary', 0)} !important;
  @media (max-width: 325px) {
    padding: 0px 13px !important;
  }
}

/* MULTI SELECT */

.ant-select-selection:hover, .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
  border-color: ${palette('primary', 0)} !important;
}
.ant-select-selection:focus, .ant-select-open .ant-select-selection  {
  box-shadow: 0 0 0 2px rgba(191,34,0,0.05) !important;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled), .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: rgba(191,34,0,0.05) !important;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon, .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
  color: ${palette('primary', 0)} !important;
}

/* LIST */

.ant-list-item-meta-title {
  color: ${palette('text', 0)} !important;
}

/* DATEPICKER */

.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled), & .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: ${palette('primary', 0)} !important;
  box-shadow: 0 0 0 2px ${palette('primary', 14)} !important;
}
.ant-calendar {
  & .ant-calendar-date:hover, .ant-calendar-range .ant-calendar-in-range-cell::before {
    background: ${palette('primary', 14)} !important;
  }

  &.ant-calendar-range {
    @media (max-width: 580px) {
      overflow: scroll !important;
    }
    & .ant-calendar-selected-start-date, .ant-calendar-selected-end-date {
      & .ant-calendar-date {
        background: ${palette('primary', 0)} !important;
      } 
    }
    & .ant-calendar-in-range-cell::before {
      background: ${palette('primary', 14)};
    }
  }
  & .ant-calendar-today:not(.ant-calendar-disabled-cell) .ant-calendar-date {
    color: ${palette('primary', 0)};
    border-color: ${palette('primary', 0)};
    background: ${palette('primary', 14)};
  }
  & .ant-calendar-selected-day:not(.ant-calendar-disabled-cell) .ant-calendar-date {
    background: ${palette('primary', 0)} !important;
    color: #FFF !important;
  }
  & .ant-tag-blue {
    color: ${palette('primary', 0)};
    background: ${palette('primary', 14)};
    border-color: ${palette('primary', 1)};
  }
  & .ant-calendar-ok-btn:active, .ant-calendar-ok-btn.active, .ant-calendar-ok-btn:not(.ant-calendar-ok-btn-disabled) {
    background-color: ${palette('primary', 0)} !important;
    border-color: ${palette('primary', 0)} !important;
    color: #fff !important;
  }

  &.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn:not(.ant-calendar-time-picker-btn-disabled), .ant-calendar-footer-btn .ant-calendar-today-btn, & .ant-calendar-header a:hover  {
    color: ${palette('primary', 0)};
  }
  & .ant-calendar-year-panel-year:hover, & .ant-calendar-decade-panel-decade:hover, & .ant-calendar-month-panel-month:hover {
    background-color: ${palette('primary', 14)};
  }
  & .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year, & .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade, & .ant-calendar-month-panel-selected-cell  .ant-calendar-month-panel-month{
    background-color: ${palette('primary', 0)};
    color: #fff !important;
    &:hover {
      background-color: ${palette('primary', 0)};
    }
  }
}

/* DRAWER  */
.ant-drawer-content-wrapper {
  max-width: 100%

  & .ant-drawer-body {
    padding-bottom: 80px;
    @media (max-width: 768px) {
      padding-bottom: 140px !important;
    }

    & .bottomBtnWrapper {
      z-index: 999;
      @media (max-width: 768px) {
        padding-bottom: 67px !important;
      }
    }
  }
}

/* PHOTO UPLOAD */
& .ant-upload.ant-upload-select-picture-card:hover {
  border-color: ${palette('primary', 0)} !important;
}

/* RADIO BUTTONS */
.ant-radio-group {
  .ant-radio-checked .ant-radio-inner, .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${palette('primary', 0)};
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before, .ant-radio-checked .ant-radio-inner::after {
    background-color: ${palette('primary', 0)} !important;
  }
}

.ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled), .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  background-color: ${palette('primary', 0)} !important;
  background: ${palette('primary', 0)} !important;
  border-color: ${palette('primary', 0)} !important;
  box-shadow: -1px 0 0 0 ${palette('primary', 0)} !important;
}

.ant-radio-group .ant-radio-button-wrapper:hover {
  color: ${palette('primary', 0)};
}

/* DROPDOWN MENU CSS */
.ant-menu {
  .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover, 
  .ant-menu-item-selected {
    color: ${palette('primary', 0)};
  }
}
div .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: ${palette('primary', 14)};
}

.ant-menu.langMenu {
  border-right: 0px;
  & .ant-menu-item {
    // height: 30px;
    // padding: 0;
    min-width: 80px;
  }
}

/* POPOVER CSS */
.ant-popover-placement-bottomLeft {
  & .ant-popover-arrow {
    @media (max-width: 425px) {
      display: none;
    }
  }
}


`;

export default GlobalStyles;
