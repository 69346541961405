import { userRoles } from '../../../../constants/constants';

const options = [
  {
    key: 'calendar',
    userType: [userRoles.admin, userRoles.doctor],
    label: 'sidebar.calendar',
    leftIcon: 'ion-calendar',
    img: 'calendar',
  },
  {
    key: 'appointments',
    userType: [userRoles.patient, userRoles.doctor],
    label: 'sidebar.Appointments',
    leftIcon: 'ion-calendar',
    img: 'appointment',
  },
  {
    key: 'chat',
    userType: [userRoles.patient],
    label: 'sidebar.chat',
    leftIcon: 'ion-chatbubbles',
    img: 'chat',
  },
  {
    key: 'chat',
    userType: [userRoles.doctor],
    label: 'sidebar.chat',
    leftIcon: 'ion-chatbubbles',
    img: 'chat',
  },
  {
    key: 'therapist',
    userType: [userRoles.admin, userRoles.doctor],
    label: 'sidebar.Therapist',
    leftIcon: 'ion-android-contacts',
    img: 'therapist',
  },
  {
    key: 'patient',
    userType: [userRoles.admin, userRoles.doctor],
    label: 'sidebar.Patients',
    leftIcon: 'ion-person',
    img: 'patient',
  },
  {
    key: 'file-manager',
    userType: [userRoles.patient, userRoles.doctor],
    label: 'sidebar.FileManager',
    leftIcon: 'ion-document',
    img: 'file-manager',
  },
  {
    key: 'my-protocols',
    label: 'My Protocols',
    leftIcon: 'ion-chatboxes',
    userType: [userRoles.doctor],
    img: 'protocol',
  },
  {
    key: 'manage-protocols',
    label: 'Manage Protocols',
    userType: [userRoles.patient],
    leftIcon: 'ion-aperture',
    img: 'protocol',
    children: [
      {
        key: 'all-protocols',
        label: 'sidebar.allProtocols',
      },
      {
        key: 'implementations',
        label: 'sidebar.implementations',
      },
      {
        key: 'patient-conditions',
        label: 'sidebar.patientConditions',
      },
    ],
  },
  {
    key: 'all-feedback',
    label: 'All Feedback',
    leftIcon: 'ion-chatboxes',
    userType: [userRoles.patient],
    img: 'feedback',
  },
  {
    key: 'invoices',
    label: 'sidebar.invoice',
    leftIcon: 'ion-clipboard',
    userType: [userRoles.patient],
    img: 'Invoice',
  },
  {
    key: 'new-email',
    userType: [userRoles.patient],
    label: 'sidebar.Emails',
    leftIcon: 'ion-email',
    img: 'Email',
  },
  {
    key: 'blogs',
    userType: [userRoles.patient],
    label: 'sidebar.Blogs',
    leftIcon: 'ion-document-text',
    img: 'blog',
  },
  {
    key: 'services',
    userType: [userRoles.patient],
    label: 'sidebar.Services',
    leftIcon: 'ion-settings',
    img: 'services',
  },
  {
    key: 'cms',
    userType: [userRoles.patient],
    label: 'sidebar.Cms',
    leftIcon: 'ion-document-text',
    img: 'cms',
  },
  {
    key: 'manage-admin',
    userType: ['admin'],
    label: 'sidebar.ManageAdmin',
    leftIcon: 'ion-android-contacts',
    img: 'admin',
  },
  {
    key: 'languages mgt',
    userType: [userRoles.patient],
    label: 'sidebar.Languages',
    leftIcon: 'ion-android-globe',
    children: [
      {
        key: 'languages',
        label: 'sidebar.Languages',
      },
      {
        key: 'all-words',
        label: 'sidebar.AllWords',
      },
    ],
  },
  {
    key: 'settings',
    userType: [userRoles.patient],
    label: 'themeSwitcher.settings',
    leftIcon: 'ion-settings',
    img: 'settings',
  },
  {
    key: 'delete-requests',
    label: 'Delete Requests',
    leftIcon: 'ion-document',
    userType: [userRoles.patient],
    img: 'protocol',
  },
  {
    key: 'legal',
    userType: [userRoles.doctor],
    label: 'Legal',
    leftIcon: 'ion-android-globe',
    img: 'file-manager',
    children: [
      {
        type: 'frontend',
        key: 'impressum',
        label: 'Imprint',
      },
      {
        key: 'datenschutz',
        type: 'frontend',
        label: 'Data Protection',
      },
      {
        key: 'allgemeine-geschaeftsbedingungen',
        type: 'frontend',
        label: 'Terms of Service',
      },
    ],
  },
  {
    key: 'insurance-management',
    userType: [userRoles.patient],
    label: 'sidebar.Insurance',
    leftIcon: 'ion-email',
    img: 'insurance',
    children: [
      {
        key: 'insurance',
        label: 'sidebar.TypeManagement',
        userType: [userRoles.patient],
      },
      {
        key: 'insurance-type',
        label: 'sidebar.InsuranceManagement',
        userType: [userRoles.patient],
      },
    ],
  },
  {
    key: 'insurance-request',
    label: 'sidebar.insurancerequest',
    img: 'insurance',
    userType: [userRoles.patient],
  },
  {
    key: 'location',
    label: 'sidebar.location',
    img: 'location',
    userType: [userRoles.patient],
  },
  {
    key: 'manage-cities',
    label: 'sidebar.managecity',
    img: 'location',
    userType: [userRoles.patient],
  },
  {
    key: 'career',
    label: 'career.title',
    img: 'suitcase',
    userType: [userRoles.patient],
  },
];
export default options;
