import siteConfig from '../../../../config/site.config';
import { getToken, getLanguage } from './utility';
import Notification from '../../../../components/Notification';
import authAction from '../../../../redux/auth/actions';
import { store } from '../../../../redux/store';

const defaultHeader = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
};

function apiCall(url, data = {}, methodParm = 'GET', headers = defaultHeader, formData = false) {
  const method = methodParm.toUpperCase();
  const token = getToken().get('idToken');
  const lang = getLanguage().get('language');
  if (token) headers.Authorization = `Bearer ${token}`;
  headers.language = lang || 'en';
  let options = {
    method,
    headers
  };
  let query = '';
  let qs = '';

  const dataLength = data ? Object.keys(data).length : 0;
  const body = formData ? data : JSON.stringify(data);

  if (method === 'POST' || method === 'PUT') options = { ...options, body };
  if (method === 'GET' && dataLength > 0) {
    Object.keys(data).map((key, i) => {
      const sep = i === dataLength - 1 ? '' : '&';
      query += `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}${sep}`;
    });
    qs = `?${query}`;
  }
  return new Promise((resolve, reject) => {
    fetch(`${siteConfig.apiUrl}${url}${qs}`, options)
      .then((response) => response.json())
      .then((resposeJson) => {
        if (resposeJson && token && resposeJson.message === 'Unauthorized' && store) {
          store.dispatch(authAction.setLoggedOut(true));
          const auth = store ? store.getState().Auth : {};
          if (!auth.loggedOut) {
            Notification('error', 'Sitzung abgelaufen, Bitte erneut anmelden');
            setTimeout(() => {
              store.dispatch(authAction.logout());
            }, 500);
          }
        } else {
          resolve(resposeJson);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export default apiCall;
