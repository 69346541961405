import { notification } from 'antd';
import { has, isObject } from 'lodash';
import { store } from '../redux/store';

function createNotification(type, message = type === 'success' ? 'Success!' : 'Something went wrong. Try later', description) {
  const state = store ? store.getState() : {};
  const allWords = isObject(state) && has(state, 'LanguageSwitcher.allWords') ? state.LanguageSwitcher.allWords : {};
  const transMsg = isObject(allWords) && allWords[message] ? allWords[message] : message;
  type === 'error'
    ? notification.error({
      message: transMsg,
      description
    })
    : notification.success({
      message: transMsg,
      description
    });
}
export default createNotification;
