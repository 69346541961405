import React from 'react';
import ImageCellView from './ImageCell';
import DeleteCell from './DeleteCell';
import EditableCell from './EditableCell';
import FilterDropdown from './FilterDropdown';

function DateCell(data) {
  return <p>{data.toLocaleString()}</p>;
}
function ImageCell(src) {
  return <ImageCellView src={src} />;
}
function LinkCell(link, href) {
  return <a href={href || '#'}>{link}</a>;
}
function TextCell(text) {
  return <p>{text}</p>;
}

export {
  DateCell,
  ImageCell,
  LinkCell,
  TextCell,
  EditableCell,
  DeleteCell,
  FilterDropdown,
};
