import AppLocale from '../config/translation';
import { LANGS } from '../constants/constants';

export const languageHelpers = () => {
  let appLanguage = Object.keys(AppLocale)[0];
  const locale = window.navigator.language;
  const localeShort = locale.split(/[_-]/)[0];
  const isPresent = Object.keys(AppLocale).find((item) => item === localeShort);
  const storeLang = localStorage.getItem('language');
  if (storeLang) {
    appLanguage = storeLang;
  } else if (isPresent) {
    appLanguage = localeShort;
  }
  return appLanguage;
};

export const getLangCode = (language) => {
  return language && language.code ? language.code : languageHelpers();
};

export const getLanguageId = (languageCode) => {
  let result;
  if (languageCode === LANGS.DE) {
    result = 1;
  } else result = 2;
  return result;
};
