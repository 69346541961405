import chatActions from '../chat/actions';

const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  SET_USER: 'SET_USER',
  SET_LOGGED_OUT: 'SET_LOGGED_OUT',
  SET_USER_TYPE: 'SET_USER_TYPE',
  SET_USER_PROFILE: 'SET_USER_PROFILE',

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (resData) => {
    return ({
      type: actions.LOGIN_REQUEST,
      payload: {
        accessToken: resData.accessToken,
        refreshToken: resData.refreshToken,
        user: resData.user
      }
    });
  },
  setUserObj: (user) => ({
    type: actions.SET_USER,
    user
  }),
  setLoggedOut: (loading) => ({
    type: actions.SET_LOGGED_OUT,
    loading
  }),
  logout: () => (dispatch, getState) => {
    const { IOSocket } = getState().Chat;
    if (IOSocket) {
      IOSocket.disconnect();
      dispatch(chatActions.clearChatData());
    }
    dispatch({
      type: actions.LOGOUT
    });
  },
  setUserType: (userType) => ({
    type: actions.SET_USER_TYPE,
    userType
  }),
  setUserProfile: (user) => ({
    type: actions.SET_USER_PROFILE,
    user
  }),
};
export default actions;
