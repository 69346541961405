export const limitPerPage = 25;
export const firstPage = 1;
export const maxItemsLimitPerPage = 100;
export const DEFAULT_DELAY = 300;
export const SIDEBAR_COLLAPSE_TIMEOUT = 100;
export const serviceCategoryIds = {
  couples: 2,
  psychotherapy: 1,
  coaching: 3,
};

export const LANGS = {
  EN: 'en',
  DE: 'de'
};

export const USER_ROLES = {
  ADMIN: 'admin',
  DOCTOR: 'doctor',
  PATIENT: 'patient',
  CHILD: 'child',
  INTERN: 'intern'
};

export const INVOICE_TYPES = {
  REFUND: 'refund',
};

export const BLOGS_PER_PAGE = 10;
export const ONE_HUNDRED = 100;
export const DEFAULT_LIMIT = 10;

export const IMAGE_FILE_SIZE = 1024;
export const DEFAULT_FILE_SIZE_LIMITATION = 16;
export const IMAGE_FILE_SIZE_COMPARISON = 1024 < 2;

export const userRoles = {
  admin: 'admin',
  child: 'child',
  patient: 'patient',
  doctor: 'doctor',
  intern: 'intern',
};

export const HOURS_SESSION_SLOTS = [
  '04:00-04:50',
  '05:00-05:50',
  '06:00-06:50',
  '07:00-07:50',
  '08:00-08:50',
  '09:00-09:50',
  '10:00-10:50',
  '11:00-11:50',
  '12:00-12:50',
  '13:00-13:50',
  '14:00-14:50',
  '15:00-15:50',
  '16:00-16:50',
  '17:00-17:50',
  '18:00-18:50',
  '19:00-19:50',
  '20:00-20:50',
];

export const DAYS_WITH_SESSION_TIME_SLOTS = [
  { day: 1, slots: HOURS_SESSION_SLOTS },
  { day: 2, slots: HOURS_SESSION_SLOTS },
  { day: 3, slots: HOURS_SESSION_SLOTS },
  { day: 4, slots: HOURS_SESSION_SLOTS },
  { day: 5, slots: HOURS_SESSION_SLOTS },
  { day: 6, slots: HOURS_SESSION_SLOTS },
  { day: 7, slots: HOURS_SESSION_SLOTS },
];

export const WEEK_DAYS = [
  { day: 1 },
  { day: 2 },
  { day: 3 },
  { day: 4 },
  { day: 5 },
  { day: 6 },
  { day: 7 },
];

export const OFFLINE = 'offline';
export const ONLINE = 'online';
export const ACTIONS = {
  DELETE: 'delete',
  EDIT: 'edit',
  ADD: 'add',
  SUBMIT: 'submit',
};

export const ONLINE_TYPES = {
  ONLINE: 'online',
  OFFLINE: 'offline',
};

export const APPOINTMENT_STATUSES = {
  REQUESTED: 'requested',
  PAYMENT_REQUESTED: 'paymentRequested',
  CONFIRMED: 'confirmed',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
  PROTOCOL: 'protocol',
  REFUNDED: 'refunded',
};

export const APPOINTMENT_STATUS_ALLOW_TO_CHANGE = [
  APPOINTMENT_STATUSES.REQUESTED,
  APPOINTMENT_STATUSES.PAYMENT_REQUESTED,
  APPOINTMENT_STATUSES.CONFIRMED,
];

export const SORT_TYPES = {
  ASC: 'asc',
  DESC: 'desc',
  SORT_ACS: 'sort-ascending',
  SORT_DESC: 'sort-descending',
  THERAPIST: 'therapist',
  PATIENT: 'patient',
  SERVICE: 'service',
  STATUS: 'status',
  SORTING: 'sorting',
  DESCEND: 'descend',
  ASCEND: 'ascend',
};

export const HTTP_METHODS = {
  POST: 'POST',
  PATCH: 'PATCH',
  GET: 'GET',
  DELETE: 'DELETE',
  PUT: 'PUT',
};

export const CALENDAR = {
  HEIGHT: 830,
  CULTURE_DE: 'de-DE',
  CULTURE_EN: 'en-GB',
  FILTERS: {
    THERAPIST: 'therapist',
    SERVICE: 'service',
    PATIENT: 'patient',
    STATUS: 'status',
    DATE: 'date',
  }
};

export const DATE_FORMATS = {
  DATE: 'YYYY-MM-DD',
  DATE_REVERSE: 'DD-MM-YYYY',
  DATE_REVERSE_WITH_MINUTES: 'DD-MM-YYYY H:m',
};

export const MOBILE_VIEW = 'MobileView';

export const CALENDAR_TIMESTAMPS = {
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day',
  AGENDA: 'agenda',
};

export const API_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
};

export const INVOICE_TEMPLATE_PLACEHOLDERS = {
  PATIENT_NAME: '{{patient_name}}',
  PATIENT_ADDRESS: '{{patient_address}}',
  CITY: '{{city}}',
  POST_CODE: '{{postCode}',
  COUNTRY: '{{country}}',
  PHONE: '{{phone}}',
  EMAIL: '{{email}}',
  ZIP_CODE: '{{zipCode}}',
  PAYMENT_METHOD: '{{payment_method}}',
  THERAPIE_NAME: '{{therapie_name}}',
  SERVICE_NAME: '{{service_name}}',
  CODE: '{{code}}',
  PRICE: '{{price}}',
  TOTAL_PRICE: '{{total_price}}',
  BOOKING_DATE: '{{booking_date}}',
  TIME_SLOT: '{{time-slot}}',
  INVOICE_DATE: '{{invoice_date}}',
  KID_NAME: '{{kid_name}}',
  INVOICE_NO: '{{invoice_no}}',
  CANCEL_BOX: '{{cancel_box}}',
  CANCEL_REASON: '{{cancel_reason}}',
};

export const ALLOWED_FILE_FORMATS = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
];

export const CHAT_ACTIONS = {
  JOIN_ROOM: 'joinRoom',
  MESSAGE: 'message',
  DELETE_MESSAGE: 'deleteMessage',
  GET_MY_CHATS: 'getMyChats',
  GET_ALL_CHATS: 'getAllChats',
  MESSAGE_DELETED: 'messageDeleted',
};

export const FILE_MANAGER_TYPES = {
  FOLDER: 'folder',
  FILE: 'file',
  BACK: 'back',
};

export const FEEDBACK_FILTER_TYPES = {
  CHAT_VIEW: 'ChatView',
  TAB_VIEW: 'TabView',
};

export const PERMISSION_NAMES = {
  CALENDAR: 'calendar',
  APPOINTMENT: 'appointment',
  SCHEDULE: 'availabilityCalendar',
  CHAT: 'chat',
  THERAPISTS: 'therapists',
  PATIENTS: 'patients',
  KIDS: 'kids',
  NEW_REQUEST: 'registrationRequest',
  FILE_MANAGER: 'fileManager',
  PROTOCOLS: 'protocols',
  INVOICE: 'invoice',
  EXTERNAL_INVOICE: 'externalInvoice',
  EMAILS: 'emails',
  PAYMENT_METHODS: 'paymentMethods',
  LOCATION: 'location',
  PRAXIS_LOCATION: 'praxisLocation',
  ARCHIVED_USERS: 'archive',
  CAREER: 'career',
  BLOGS: 'blogs',
  EXERCISES: 'exercises',
  SERVICES: 'services',
  MANAGE_ADMINS: 'manageAdmins',
  LANGUAGES: 'languages',
  DELETE_REQUEST: 'deleteRequest',
  BOOKING_EMAIL: 'bookingEmail',
  FAQ: 'faq',
};

export const ADMIN_PERMISSIONS = [
  {
    label: 'sidebar.calendar',
    value: PERMISSION_NAMES.CALENDAR
  },
  {
    label: 'sidebar.Appointments',
    value: PERMISSION_NAMES.APPOINTMENT
  },
  {
    label: 'sidebar.availableSlot',
    value: PERMISSION_NAMES.SCHEDULE
  },
  {
    label: 'sidebar.chat',
    value: PERMISSION_NAMES.CHAT
  },
  {
    label: 'sidebar.Therapist',
    value: PERMISSION_NAMES.THERAPISTS
  },
  {
    label: 'sidebar.managecity',
    value: PERMISSION_NAMES.PRAXIS_LOCATION
  },
  {
    label: 'sidebar.Patients',
    value: PERMISSION_NAMES.PATIENTS
  },
  {
    label: 'sidebar.Kids',
    value: PERMISSION_NAMES.KIDS
  },
  {
    label: 'sidebar.newRequest',
    value: PERMISSION_NAMES.NEW_REQUEST
  },
  {
    label: 'sidebar.FileManager',
    value: PERMISSION_NAMES.FILE_MANAGER
  },
  {
    label: 'sidebar.allProtocols',
    value: PERMISSION_NAMES.PROTOCOLS
  },
  {
    label: 'sidebar.invoice',
    value: PERMISSION_NAMES.INVOICE
  },
  {
    label: 'sidebar.Notes',
    value: PERMISSION_NAMES.FAQ
  },
  {
    label: 'sidebar.Emails',
    value: PERMISSION_NAMES.EMAILS
  },
  {
    label: 'sidebar.Insurance',
    value: PERMISSION_NAMES.PAYMENT_METHODS
  },
  {
    label: 'sidebar.location',
    value: PERMISSION_NAMES.LOCATION
  },
  {
    label: 'sidebar.archivedUsers',
    value: PERMISSION_NAMES.ARCHIVED_USERS
  },
  {
    label: 'career.title',
    value: PERMISSION_NAMES.CAREER
  },
  {
    label: 'sidebar.Blogs',
    value: PERMISSION_NAMES.BLOGS
  },
  {
    label: 'sidebar.Kurzubungen',
    value: PERMISSION_NAMES.EXERCISES
  },
  {
    label: 'sidebar.Services',
    value: PERMISSION_NAMES.SERVICES
  },
  {
    label: 'sidebar.ManageAdmin',
    value: PERMISSION_NAMES.MANAGE_ADMINS
  },
  {
    label: 'sidebar.Languages',
    value: PERMISSION_NAMES.LANGUAGES
  },
  {
    label: 'sidebar.deleteRequests',
    value: PERMISSION_NAMES.DELETE_REQUEST
  }
];

export const STICKER_WIDGET_DATA = [
  {
    id: 'curr_month_amount',
    text: 'Total amount of all invoices generated this month',
    icon: 'ion-android-calendar',
    fontColor: '#ffffff',
    bgColor: '#F75D81',
    currency: '€',
  },
  {
    id: 'curr_year_amount',
    text: 'Total amount of all invoices generated this year',
    icon: 'ion-android-calendar',
    fontColor: '#ffffff',
    bgColor: '#7266BA',
    currency: '€',
  },
  {
    id: 'number_requested_appoinment_total',
    text: 'Total amount of requested appointments',
    icon: 'ion-person',
    fontColor: '#ffffff',
    bgColor: '#42A5F6',
  },
  {
    id: 'number_protocol_appoinment_total',
    text: 'Total amount of protocol appointments',
    icon: 'ion-person',
    fontColor: '#ffffff',
    bgColor: '#7ED320',
  },
  {
    id: 'completed_curr_month_amount',
    text: 'Total amount of appointments completed this month',
    icon: 'ion-person',
    fontColor: '#ffffff',
    bgColor: '#7ED320',
  },
  {
    id: 'completed_curr_year_amount',
    text: 'Total amount of appointments completed this year',
    icon: 'ion-person',
    fontColor: '#ffffff',
    bgColor: '#333333',
  },
];

export const PAYMENT_METHODS_SLUGS = {
  SELF_PAYERS: 'self-payers'
};

export const TRANSLATION_TYPES = {
  WEBSITE: 'website',
  ADMIN: 'admin',
  ERROR: 'error',
  MESSAGE: 'message'
};
