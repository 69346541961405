import { getParsedJson } from '../../shared/common/library/helpers/utility';
import actions from './actions';

const initState = {
  accessToken: null,
  refreshToken: null,
  userData: {},
  loggedOut: false
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
        userData: action.user ? getParsedJson(action.user) : {}
      };
    case actions.LOGOUT:
      return initState;
    case actions.SET_LOGGED_OUT:
      return {
        ...initState,
        loggedOut: action.loading,
      };
    case actions.SET_USER_TYPE:
      return {
        ...state,
        userData: { ...state.userData, user_type: action.userType }
      };
    case actions.SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.user,
      };
    default:
      return state;
  }
}
