import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ConfigProvider } from 'antd';
import { isEmpty, isObject } from 'lodash';
import { IntlProvider, addLocaleData } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import deDe from 'antd/lib/locale-provider/de_DE';

import themes from './config/theme/theme.config';
import getApiData from './shared/common/library/helpers/apiHelper';
import langActions from './redux/languageSwitcher/actions';
import AppLocale from './config/translation';
import { getToken } from './shared/common/library/helpers/utility';
import authActions from './redux/auth/actions';
import { getLangCode } from './utils/languageHelpers';

import EN from './i18n/en/general';
import DE from './i18n/de/general';
import { LANGS, USER_ROLES } from './constants/constants';

const { setAllWords } = langActions;
const { setUserObj } = authActions;

export default function AppProvider({ children }) {
  const language = useSelector((state) => state.LanguageSwitcher.language);
  const dispatch = useDispatch();
  // const allWords = useSelector((state) => state.LanguageSwitcher.allWords);
  const [messages, setMessages] = useState({});
  const { themeName } = useSelector(
    (state) => state.ThemeSwitcher.changeThemes
  );
  const lang_code = getLangCode(language);
  const currentAppLocale = AppLocale.de;
  const token = getToken().get('idToken');
  const user = useSelector((state) => state.Auth.userData);
  const userType = isObject(user) && user.user_type ? user.user_type : '';

  useEffect(() => {
    const localeData = require(`react-intl/locale-data/${lang_code}`);
    addLocaleData(localeData.default);

    if (lang_code === LANGS.DE) {
      setMessages(DE);
      setAllWords(DE);
    } else {
      setMessages(EN);
      setAllWords(EN);
    }
  }, [lang_code]);

  // this function for get user data
  async function getUserData() {
    try {
      const response = await getApiData('getTherapieDetail', {}, 'POST');
      if (response.success && !isEmpty(response.user)) {
        dispatch(setUserObj(response.user, token));
      }
    } catch (err) {
      console.log('err====?');
      console.log(err);
    }
  }

  useEffect(() => {
    if (token !== '' && userType === USER_ROLES.DOCTOR) {
      getUserData();
    }
  }, [token]);

  (async () => {
    const localeData = await import(`react-intl/locale-data/${lang_code}`);
    addLocaleData(localeData.default);
  })();

  return (
    <ConfigProvider locale={lang_code === 'de' ? deDe : undefined}>
      <IntlProvider
        locale={lang_code}
        messages={
          isObject(messages) && !isEmpty(messages)
            ? messages
            : currentAppLocale.messages
        }
      >
        <ThemeProvider theme={themes[themeName]}>{children}</ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};
